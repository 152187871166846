@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Color styles */
  --white: #ffffff;
  --dark-mode-bg: #211a4a;
  --light-mode-bg: #ffffff;
  --candy-linear-gradient: linear-gradient(91.13deg, #d85980 -4.51%, #ff61c0 102.47%);
  --teal: #5ac8fb;

  /* candy */
  --candy-0: #2b121b;
  --candy-1: #562436;
  --candy-2: #973e5f;
  --candy-3: #d85988;
  --candy-4: #e48bac;
  --candy-5: #efbdd0;
  --candy-6: #f7dee7;

  /* neutral-dark */
  --neutral-dark-0: #1c133d;
  --neutral-dark-1: #2e1854;
  --neutral-dark-2: #2d225e;
  --neutral-dark-3: #402d82;
  --neutral-dark-4: #6951b1;
  --neutral-dark-5: #9b8bd8;
  --neutral-dark-6: #dfd0fa;

  /* neutral-light */
  --neutral-light-0: #eeedf6;
  --neutral-light-1: #d4d2e2;
  --neutral-light-2: #afacc6;
  --neutral-light-3: #625e7c;
  --neutral-light-3: #7e7a98;
  --neutral-light-4: #393558;
  --neutral-light-5: #1c1937;
  --neutral-light-6: #0c0a21;

  /* grey */
  --grey-0: #f3f4f6;
  --grey-1: #e5e7eb;
  --grey-2: #d1d5db;
  --grey-3: #9ca3af;
  --grey-4: #6b7280;
  --grey-5: #4b5563;
  --grey-6: #374151;
  --grey-7: #1f2937;
  --grey-8: #111827;

  /* blue */
  --blue-0: #14282e;
  --blue-1: #274f5c;
  --blue-2: #4f9eb7;
  --blue-3: #62c6e5;
  --blue-4: #81d1ea;
  --blue-5: #c0e8f6;
  --blue-6: #e0f4fa;

  /* purple */
  --purple-0: #100c28;
  --purple-1: #201850;
  --purple-2: #3f30a1;
  --purple-3: #4f3cc9;
  --purple-4: #7263d4;
  --purple-5: #b9b2e9;
  --purple-6: #ddd8f4;

  /* Text-size styles */
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;
  --font-size-xxl: 20px;
  --font-size-xxxl: 24px;
  --font-size-xxxxl: 38px;
  --font-family-lato: 'Lato';
}

.container {
  @apply xl:!max-w-7xl px-4;
}

html,
body {
  background: var(--neutral-light-6);
}
